import * as React from "react";
import type { SVGProps } from "react";
const SvgLightningChargeFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38}
    height={38}
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <g clipPath="url(#lightning-charge-fill_svg__a)">
      <path
        fill="#3B82F6"
        d="M26.722.163a1.19 1.19 0 0 1 .539 1.377l-4.278 13.899h7.892a1.188 1.188 0 0 1 .865 2.002l-19 20.187a1.188 1.188 0 0 1-2-1.164l4.278-13.9H7.125a1.187 1.187 0 0 1-.864-2.003l19-20.187a1.19 1.19 0 0 1 1.46-.214z"
      />
    </g>
    <defs>
      <clipPath id="lightning-charge-fill_svg__a">
        <path fill="#fff" d="M0 0h38v38H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLightningChargeFill;
